body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

video {
  max-height: calc(100vh - 65px);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink-blue {
  0% {
    color: rgba(0, 219, 255, 0);
  }
  100% {
    color: rgba(0, 219, 255, 1);
  }
}

@keyframes blink-blue {
  0% {
    color: rgba(0, 219, 255, 0);
  }
  100% {
    color: rgba(0, 219, 255, 1);
  }
}






/* Aarontti layout */



:root 
{
    --border-radius: 5px;
}

/* Main background */
body 
{
    background-color: rgb(36, 36, 36) !important;
}

/* Sidebar */
.css-1elmcen 
{
    border: none !important;
    background-color: rgb(23, 23, 23) !important;
}
/* Sidebar shadow */
.css-1o5pq7n
{
    box-shadow: 0 0 12px 12px #00000016 !important;
}

/* Gradiented things */
.css-uac6ug.Mui-selected, .css-uac6ug, .css-1udnj, /* Colored sidebar buttons */
.css-1uqnrip, /* Misc buttons in settings */
.css-8pniwb, /* Video player media controls */
.css-1bgvp7n /* Notification popup */
{
    background-image: linear-gradient(140deg, rgba(188, 0, 230, 0.875), rgba(255, 55, 41, 0.85)) !important;
}
/* Extra things for specifically gradiented buttons */
.css-uac6ug.Mui-selected, .css-uac6ug, .css-1udnj, /* Colored sidebar buttons */
.css-1uqnrip, /* Misc buttons in settings */
.css-8pniwb /* Video player media controls */
{
    color: white !important;
    border: none !important;
    transition: ease-in-out 0.15s background-color !important;
}
.css-uac6ug.Mui-selected:hover,
.css-uac6ug:hover,
.css-1udnj:hover,
.css-1uqnrip:hover,
.css-8pniwb:hover
{
    background-color: rgb(230, 84, 66) !important;
}

/* Sidebar buttons gap */
.css-uac6ug
{
    margin-right: 8px !important;
}
.css-1udnj
{
    margin-top: 8px !important;
}

/* List view item background */
.css-1jila38
{
    background-color: rgb(45, 45, 45) !important;
}
.css-177vpa4
{
    background: none !important;
}

/* Header bar */
.css-1zunsc, .css-176gaxx
{
    background-color: rgba(17, 17, 17, 0.82) !important;
}

/* Video card title bar */
.css-161wjsb 
{
    background: rgb(23, 23, 23) !important;
}

/* "Toggle public visibility" button on video cards */
.css-cckiw2
{
    display: none !important;
}

/* Search bar */
.css-sc9576-control 
{
    background-color: rgba(17, 17, 17, 0.82) !important;
    border: none !important;
}

/* Video length indicator repositioning on video cards */
.css-12cmwa2
{
    bottom: 14px !important;
}
/* Copy link -button top left lock */
.css-qqx558
{
    top: 110px;    
}

/* Video card restyling for consistent heights (only minimum size) */
.css-b5r2po
{
    max-height: 180px !important;
    overflow: hidden !important;
    border-radius: 5px !important;
    margin: 4px !important;
}

/* Video player centering and width limit */
div.MuiPaper-rounded:nth-child(3) 
{
    max-width: 1200px !important;
    margin:auto !important;
}

/* Restrict main video player width to fit onto page */
video
{
    max-height: 80vh !important;
}

/* Hide video player "X" background */
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeMedium.css-kacvw0
{
    background: none !important;
}

/* Video player central background */
.css-3waa1v
{
    /*background: rgba(0, 0, 0, 0.85);
    max-height: 100vh;
    
    border-left: 1px solid #ffffff29;
    border-right: 1px solid #ffffff29;
    box-shadow: 0 0 32px 32px #0000009c;*/
    
    padding-top: 7.5vh !important;
    background: none !important;
    transition: ease-in-out 1s !important;
}

/* Video player full background */
.css-919eu4
{
    background: rgba(0, 0, 0, 0.9) !important;
}

/* Remove most ugly blue outlines */
.MuiGrid-root.MuiGrid-item *:not(.css-1kbkigo) /* Ignore drag and drop upload area */
{
    border: none !important;
}

/* Login boxes */
.MuiOutlinedInput-input.MuiInputBase-input.css-h4os0j,
.css-17opruk
{
    border: 1px solid #ffffff40 !important;   
    border-radius: var(--border-radius) !important;
}

/* Video title card title font size */
.MuiInputBase-input
{
    font-size: 0.8rem !important;   
}

/* Thumbnail size slider */
.css-pges85:hover, .css-ne7hm1:hover
{
    box-shadow: rgba(255, 0, 0, 0.16) 0px 0px 0px 8px !important;
}
/* Colored text colors */
.css-4vec7o, .css-1xs6jfg, /* Thumbnail size slider */
.css-1x43fi8, /* Version number text in bottom left */
.css-f1be5z.Mui-checked, /* Settings tickboxes */
.css-1rpprkf
{
    color: rgb(255, 51, 128) !important;
}
.css-1ij3dxa:hover, /* Copy link button on video cards */
.css-1qf4lvk:hover /* Edit button on video cards */
{
    background-color: rgb(255, 51, 128) !important;
}

/* Views and length text size on list view */
.css-16gd1
{
    font-size: 1rem !important;
}

/* Miscalleneous rounded borders */
.css-uac6ug, /* Grid list view toggle buttons */
.css-ywih35, .css-1udnj, /* Sidebar category buttons */
video,
.css-8pniwb, /* Video player media controls */
.css-i6o53a, /* Video player description writing box */
.css-1uqnrip, /* Settings buttons */
.css-13vg0rk, .css-ti9e8f, .css-k70a20, .css-1j716cy /* Edit video card stuff + Selected input box, I think */
{
    border-radius: var(--border-radius) !important;
}

/* Hide stuff */
div.MuiPaper-elevation:nth-child(1) > div:nth-child(1) > ul:nth-child(3) > li:nth-child(2) > div:nth-child(1), /* "Public videos" button from sidebar */
.css-ps20ad.Mui-selected, /* "Default video privacy" from settings */
.css-1k82qzg > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > button:nth-child(3), /* "Views" in public video player */
.css-8pniwb:nth-child(2), .css-8pniwb:nth-child(4) /* Toggle publicity and copy timestamp buttons on video player */
{
    display: none !important;
}

/* Replace "Default video privacy" with settings title */
.MuiTypography-root.MuiTypography-overline.css-fu151m 
{
    visibility: hidden !important;
}
.MuiTypography-root.MuiTypography-overline.css-fu151m::before 
{
    content: "Settings" !important; 
    visibility: visible !important; 
}

/* Video player recolors */
.css-i6o53a, /* Description writing box */
.css-1t9k589 /* Title box */
{
    background: #111 !important;
}


/* Drag and drop upload area */
.css-1kbkigo
{
    overflow: visible !important;
    border: 2px dashed #fff !important;
    transition: ease-in-out 0.1s !important;
}
.css-1kbkigo:hover
{
    background: #fff4 !important;
}

/* Public video page footer */
.css-ka3tf1 
{
    position: fixed !important; 
    left: 0 !important; 
    bottom: 0 !important; 
    padding-left: 64px !important;
    width: 100vw !important;
}

/* Center the "scan library" button in settings */
button.MuiButton-root:nth-child(1) 
{
    margin: auto !important;
}

/* Edit video card */
.css-13vg0rk 
{
  background: rgb(23, 23, 23) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

/* Limit library width */
.css-10klw3m
{
    max-width: 1400px !important;
    margin: auto !important;
}
